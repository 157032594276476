import React from 'react'
import Card from '../alarmCard'

const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')
const InfiniteScrollLayout = Loader.loadBaseComponent('ReactVirtualized', 'InfiniteScrollLayout')

class HistoryAlarmList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      handleVisible: false,
      type: 1,
      modalItem: {},
      hasLoadMore: true
    }
    this.infinitRef = React.createRef()
  }

  handleChangeYN = (item, type, e) => {
    Utils.stopPropagation(e)
    this.setState({
      modalItem: item,
      handleVisible: true,
      type
    })
  }

  handleCancel = () => {
    this.setState({
      modalItem: {},
      handleVisible: false,
      type: 1
    })
  }

  handleOk = () => {
    let { type, modalItem } = this.state
    this.props.handle(modalItem, type).then(this.handleCancel)
  }

  loadMore = () => {
    let { searchData, onTypeChange, total } = this.props
    this.setState(
      {
        hasLoadMore: false
      },
      () => {
        if (searchData.offset > total - searchData.limit) {
          return
        }
        searchData.offset += searchData.limit
        onTypeChange &&
          onTypeChange(searchData, true).then(length => {
            if (length === searchData.limit) {
              this.setState({ hasLoadMore: true })
            }
          })
      }
    )
  }

  render() {
    let { list, libType, infinitKey, pdWidth, itemWidth, itemHeight, handelAuthName, handlePageJump, taskType } = this.props
    let { handleVisible, type, hasLoadMore } = this.state
    return (
      <>
        <InfiniteScrollLayout
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          key={infinitKey}
          pdWidth={pdWidth}
          data={list}
          ref={this.infinitRef}
          hasBackTop={true}
          loadMore={this.loadMore}
          hasLoadMore={hasLoadMore}
          renderItem={(item, index) => (
            <div key={item.id}>
              <Card
                data={Object.assign(item, { imageUrl: item.picPath, deviceName: item.camreaName })}
                hasDuration={false}
                libType={libType}
                type={1}
                height={itemHeight}
                score={libType === 4 ? item.score : null}
                handleJumPage={handlePageJump}
                handleChangeYN={this.handleChangeYN}
                handelAuthName={handelAuthName}
              />
            </div>
          )}
        />
        <ConfirmComponent
          title={type == 1 ? `已违规提醒确认` : `未违规提醒确认`}
          visible={handleVisible}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
          width={320}
          icon={type == 1 ? 'icon-S_Photo_ThumbInvalid' : 'icon-S_Photo_ThumbEffective'}
          children={<div>点击“确定”将其标注为{type == 1 ? '已' : '未'}违规？</div>}
        />
      </>
    )
  }
}

export default HistoryAlarmList
