import React from 'react'
import { message } from 'antd'
import { observer } from 'mobx-react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import MonitorNavigation from '../Navigation'
import TaskList from '../taskList'
import AlarmList from '../alarmList'
import './index.less'

const NoData = Loader.loadBaseComponent('NoData')
const Loading = Loader.Loading
const LittlePagtion = Loader.loadBusinessComponent('BaseLibComponents', 'LittlePagtion')

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('tab')
@observer
class MonitorHistoryAlarmContainer extends React.Component {
  constructor(props) {
    super(props)
    const { searchDataInit, searchList, libType } = props
    this.searchList = Shared.searchList(searchList)
    this.state = {
      loading: false,
      searchData: searchDataInit,
      infinitKey: Math.random(),
      total: 0,
      dataList: {
        list: []
      } // 告警列表数据
    }
    this.listRef = React.createRef()
    this.taskListRef = React.createRef()
    this.searchOn = false
  }

  componentWillMount() {
    this.initUrlOptions()
    SocketEmitter.on(SocketEmitter.eventName.resolveControlAlarm, this.updateCard)
    SocketEmitter.on(SocketEmitter.eventName.withoutMaskMessage, this.subAlram)
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.resolveControlAlarm, this.updateCard)
    SocketEmitter.off(SocketEmitter.eventName.withoutMaskMessage, this.subAlram)
  }
  subAlram = data => {
    const { dataList } = this.state
    dataList.list.unshift(data)
    this.setState({ dataList })
  }
  updateCard = data => {
    let { dataList } = this.state
    let item = dataList.list.find(v => v.id == data.id)
    if (item) {
      item.isHandle = 1
      item.isEffective = data.isEffective
      item.violationStatus = data.violationStatus
      this.setState({ dataList }, () => {
        this.listRef.current.infinitRef.current.forceUpdateGrid()
      })
    }
  }

  /**
   * @desc 根据url获取查询条件
   */
  initUrlOptions = () => {
    const { location, searchDataInit } = this.props
    const { id } = Utils.queryFormat(location.search)
    if (id) {
      LM_DB.get('parameter', id).then(result => {
        this.setState({ searchData: { ...searchDataInit, ...result.data } })
      })
    }
  }

  /**
   * @desc 搜索条件存入indexDB
   */
  saveDbSearchData = options => {
    const id = Utils.uuid()
    const { moduleName, tab } = this.props
    LM_DB.add('parameter', {
      id,
      data: options
    }).then(() => {
      tab.goPage({
        moduleName,
        isUpdate: true,
        data: { id },
        action: 'replace'
      })
    })
  }

  // 布控详情跳转
  handlePageJump = (id, alarmData) => {
    const { tab, detailModuleName, handelAuthName } = this.props
    const { searchData, dataList } = this.state
    for (let i in searchData) {
      if (!searchData[i]) {
        delete searchData[i]
      }
    }
    const list = dataList.list
    const findIndex = list.findIndex(v => v.id === id)
    if (findIndex > -1) {
      const number = Math.floor(findIndex / searchData.limit)
      searchData.offset = number * searchData.limit
    }
    tab.openDetailPage({
      moduleName: detailModuleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        const options = {
          id: id.toString(),
          list,
          detailModuleName,
          searchData,
          handelAuthName,
          alarmData
        }
        await window.LM_DB.add('parameter', options)
      }
    })
  }

  // 搜索条件选择
  searchDataChange = async (options, loadMore = false) => {
    const { taskType } = this.props
    let { searchData, dataList, infinitKey } = this.state
    if (options.status !== undefined) {
      options.status = Array.isArray(options.status) ? options.status : [options.status]
    }
    let params = Object.assign({}, searchData, options)
    // 新增搜索前判断是否存在id，如果不存在，不搜索
    let taskIds = params.taskIds || []
    let timeType = params.timeType
    if (!taskIds.length) {
      this.setState({ searchData: params })
      return Promise.resolve(true)
    }
    this.setState({ loading: true })
    let noCapParams = {} // 新增的变量只用于接口数据的调用，不影响全局param的参数
    // prettier-ignore
    const defaultStartTime = moment().subtract(1, 'years').valueOf();
    const { startTime, endTime, status, limit, offset } = params
    noCapParams = {
      alarmOperationType: Array.isArray(status) ? formartStatus(status[0]) : undefined,
      startTime: startTime || defaultStartTime,
      endTime: endTime || Date.now(),
      taskType,
      alarmType: 1,
      taskIds,
      limit,
      offset
    }
    const res = await Service.alarmResult.getTaskAlarmsResult(noCapParams)
    const requesetList = res.data.list || []
    if (loadMore) {
      dataList.list = dataList.list.concat(requesetList)
    } else {
      dataList = res.data
      infinitKey = Math.random()
    }
    // 这里对total进行兼容，无帽进入调用另外的接口查询total
    let total = await this.getTotal(noCapParams)

    this.setState({
      loading: false,
      searchData: { ...params, timeType },
      dataList,
      infinitKey,
      total
    })
    this.searchOn && this.saveDbSearchData(params)
    this.searchOn = true
    return requesetList.length
  }

  // 无帽进入获取total
  getTotal = options => {
    return Service.alarmResult.countTaskAlarmsResult(options).then(res => {
      return res.data.total || 0
    })
  }

  // 刷新按钮
  onChange = () => {
    let { taskIds = [] } = this.state.searchData
    if (taskIds.length) {
      // 同时更新任务列表和告警列表
      this.taskListRef.current.getTaskList().then(result => {
        this.taskListRef.current.getAlarmUnResolveNum(result)
      })
      this.searchDataChange({ offset: 0 })
    }
  }

  // 修改store 参数
  setStoreSeacrhData = value => {
    const { searchData } = this.state
    let params = Object.assign({}, searchData, value)
    this.setState({ searchData: params })
  }

  // 关键字搜索
  searchInputChange = value => {
    this.searchDataChange({ monitorPersonKeywords: value, offset: 0 })
  }

  /**处理警情 */
  handle = (item, violationStatus, operationDetail = undefined) => {
    const { libType } = this.state
    const logOption = {
      libType: this.props.detailModuleName
    }
    const options = { id: item.id, isEffective: violationStatus, operationDetail }
    return Service.alarmResult.updateTaskAlarm(options, logOption).then(res => {
      message.info('设置' + (libType == 3 ? '' : '状态') + '成功')
      //更新警情
      let { list } = this.state.dataList
      let listItem = list.find(v => v.id === item.id)
      if (listItem) {
        listItem.isHandle = 1
        listItem.isEffective = violationStatus
      }
      const state = { dataList: { list } }
      this.setState(state, () => {
        this.listRef.current.infinitRef.current.forceUpdateGrid()
      })
      // 更新布控告警列表的告警数量
      return true
    })
  }

  /**新增方法 根据获取的布控任务id重新获取数据，刷新列表 获得id后，之前所有的查询条件重置*/
  getTaskId = taskIds => {
    let { searchData } = this.state
    // let {searchDataInit} = this.props
    /** 使用初始搜索条件 */
    let searchDataObj = Object.assign({}, searchData, { taskIds, offset: 0 })
    if (!taskIds.length) {
      this.setState({
        loading: false,
        total: 0,
        dataList: {
          list: []
        }
      })
      this.setStoreSeacrhData(searchDataObj)
      return Promise.resolve(true)
    }
    return this.searchDataChange(searchDataObj)
  }

  render() {
    const { dataList, loading, searchData, infinitKey, libType, total } = this.state
    const { className = '', moduleTitle, listModoleName, taskModuleName, cardType, cardStyle, taskType, handelAuthName } = this.props
    const { list } = dataList
    let alarmHandlePriv = true
    return (
      <MonitorNavigation
        moduleTitle={moduleTitle}
        currentMenu={listModoleName}
        listModoleName={listModoleName}
        taskModuleName={taskModuleName}
        taskType={taskType}
      >
        <div className={`history-alarm-wrapper ${className}`}>
          <div className="alarm-task">
            <TaskList ref={this.taskListRef} onTaskChange={this.getTaskId} types={[taskType]} isToFather={true} />
          </div>
          <div className="alarm-container">
            <div className="alarm-container-header">
              <div className="left">
                {this.searchList.map(v => (
                  <v.component
                    key={v.type}
                    libType={libType}
                    searchData={searchData}
                    onChange={this.searchDataChange}
                    onTypeChange={this.searchDataChange}
                  />
                ))}
              </div>
            </div>
            <LittlePagtion searchData={searchData} total={total || 0} loading={loading} onChange={this.onChange} />
            <div className="alarm-history-box-out">
              <React.Fragment>
                {list && list.length ? (
                  <div
                    className={`
                        task-list 
                        ${!list.length ? '' : 'no-data'}
                        ${alarmHandlePriv ? '' : 'no-handle'}
                      `}
                  >
                    <AlarmList
                      cardType={cardType}
                      {...cardStyle}
                      list={list}
                      total={total}
                      loading={loading}
                      handelAuthName={handelAuthName}
                      infinitKey={infinitKey}
                      taskType={taskType}
                      searchData={searchData}
                      handle={this.handle}
                      ref={this.listRef}
                      onTypeChange={this.searchDataChange}
                      libType={libType}
                      handlePageJump={this.handlePageJump}
                    />
                  </div>
                ) : loading ? (
                  <Loading loading={loading} />
                ) : (
                  <NoData imgType={2} title={'告警数据'} />
                )}
              </React.Fragment>
            </div>
          </div>
        </div>
      </MonitorNavigation>
    )
  }
}

const formartStatus = function(status) {
  let newStatus
  switch (status) {
    case -1:
      newStatus = 2
      break
    case 0:
      newStatus = 4
      break
    case 1:
      newStatus = 3
      break
    default:
      return
  }
  return newStatus
}

export default MonitorHistoryAlarmContainer
