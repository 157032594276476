import React from 'react'
import HistoryAlarmContainer from '../../components/HistoryAlarmContainer'

const searchDataInit = {
  startTime: undefined,
  endTime: undefined,
  timeType: undefined,
  taskId: undefined,
  offset: 0,
  limit: 40,
  status: undefined //1：已违规，2：未违规，3：未处理
}

const searchList = ['controlStateSelect', 'AlarmTimeRadio']

const noCapList = () => (
  <HistoryAlarmContainer
    listModoleName="epidemicMoniterNotify"
    taskModuleName="epidemicMoniterTask"
    manageModuleName="epidemicMoniterTaskManage"
    handelAuthName="epidemicMoniterHandle"
    moduleTitle="未戴口罩布控"
    detailModuleName="epidemicMoniterDetail"
    searchDataInit={searchDataInit}
    searchList={searchList}
    cardType="ControlCard"
    cardStyle={{
      itemWidth: 174,
      itemHeight: 320,
      pdWidth: 120
    }}
    taskType="101530"
  />
)

export default noCapList
