import React from 'react'
import { Menu } from 'antd'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import './index.less'
const IconFont = Loader.loadBaseComponent('IconFont')

@withRouter
@inject('menu', 'tab')
@observer
class MonitorNavigation extends React.Component {
  constructor(props) {
    super(props)
    const { listModoleName, taskModuleName, menu } = this.props
    const menuListNames = [listModoleName, taskModuleName]
    this.menuList = menu.authList.filter(item => menuListNames.indexOf(item.name) > -1) || []
  }
  handleClick = e => {
    const { tab, location } = this.props
    tab.goPage({
      moduleName: e.key,
      location,
      isUpdate: true,
      action: 'replace'
    })
  }
  render() {
    const { contentClass = '', currentMenu, children = null, listModoleName, taskModuleName, moduleTitle, menu } = this.props
    return (
      <React.Fragment>
        <div className="monitor-container-outer">
          <div className="monitor-header">
            <div className="monitor-title">{moduleTitle}</div>
            <Menu onClick={this.handleClick} mode="horizontal" className="menu-tab-style" selectedKeys={[currentMenu]}>
              {menu.getInfoByName(listModoleName) && (
                <Menu.Item key={listModoleName}>
                  <IconFont type="icon-S_Bar_Alarm" style={{ fontSize: '16px' }} theme="outlined" />
                  历史告警
                </Menu.Item>
              )}
              {menu.getInfoByName(taskModuleName) && (
                <Menu.Item key={taskModuleName}>
                  <IconFont type="icon-S_View_News" style={{ fontSize: '16px' }} theme="outlined" />
                  任务管理
                </Menu.Item>
              )}
            </Menu>
          </div>
          <div className={`monitor-content ${contentClass}`}>{children}</div>
        </div>
      </React.Fragment>
    )
  }
}
export default MonitorNavigation
