/*
 * @Desc: 历史告警布控任务列表
 * @Date: 2019-04-17 13:38:17
 * @Last Modified by: Joey
 * @Last Modified time: 2019-12-23 14:20:56
 */

import React from 'react'
import { Popover, List, Checkbox, message } from 'antd'
import { observer } from 'mobx-react'
import TaskListFilter from '../TaskListFilter'

import './index.less'

const NoData = Loader.loadBaseComponent('NoData')
const SearchInput = Loader.loadBaseComponent('SearchInput')
const IconFont = Loader.loadBaseComponent('IconFont')
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')
const Loading = Loader.Loading

const statusMap = [
  {
    value: [0, 1, 2, 3, 4],
    statusLabel: '全部'
  },
  {
    value: 0,
    statusLabel: '已暂停',
    statusCls: 'be-paused'
  },
  {
    value: 1,
    statusLabel: '运行中',
    statusCls: 'be-running'
  },
  {
    value: 2,
    statusLabel: '未开始',
    statusCls: 'out-of-date'
  },
  {
    value: 3,
    statusLabel: '已过期',
    statusCls: 'out-of-date'
  },
  {
    value: 4,
    statusLabel: '已删除',
    statusCls: 'be-deleted'
  }
]

/**
 * 说明
 * source：任务来源: 0全部任务  2指派任务1本地任务, 不传默认为 0
 */
@observer
class TaskList extends React.Component {
  state = {
    source: 0, // 任务来源 详见说明
    status: [1], // 任务状态
    loading: true,
    keywords: '', // 搜索框
    alarmIds: [], // 选中的布控任务id
    checkAll: false, //全选状态
    list: [], // 布控任务列表,
    confirmVisible: false // 布控告警弹窗状态
  }

  confirmOptions = {} // 确认弹窗参数

  componentDidMount() {
    const { source = 0 } = this.props
    this.setState({
      source
    })
    this.getTaskList({ source, keywords: '' }).then(result => {
      if (!result) {
        return
      }
      this.getAlarmUnResolveNum(result)
      this.getAlarmHistoryId(result, true)
    })
  }

  getAlarmUnResolveNum(list = []) {
    const ids = list.map(v => v.id)
    if (ids.length === 0) {
      return
    }
    const options = { taskIds: ids }
    options.isHandle = 0
    Service.monitorTask.countAlarmByTaskId(options).then(res => {
      if (!res.data) {
        return
      }
      list.forEach(item => {
        if (res.data[item.id]) {
          item.unhandledAlarmCount = res.data[item.id]
        }
      })
      this.setState({ list })
    })
  }

  //初始化列表时，对列表进行处理，提取告警列表id，是否同步到父组件（isToFather, 默认不同步）
  getAlarmHistoryId = (data = [], isToFather) => {
    if (!data || !data.length) {
      this.setState({
        loading: false
      })
      // return false
    }
    let alarmIds = data.map(v => v.id)
    //布控告警所有id-全局设置
    this.alarmAllIds = alarmIds
    //初始全部选中
    this.setState({
      alarmIds,
      checkAll: true
    })
    // 数据传递到父组件
    if (!isToFather) {
      this.setState({
        loading: false
      })
      return false
    }
    const { onTaskChange } = this.props
    if (onTaskChange) {
      onTaskChange(alarmIds)
        .then(res => {
          this.setState({
            loading: false
          })
          if (!res) {
            return
          }
        })
        .catch(e => {
          this.setState({
            loading: false
          })
        })
    }
  }

  /**根据条件查询任务列表 */
  getTaskList = async (option = {}) => {
    this.setState({ loading: true })
    let { source, keywords, status } = this.state
    let { types } = this.props
    let options = Object.assign({}, { types, source, keywords, limit: 500, offset: 0, status }, option)
    options.taskName = keywords
    options.type = 0
    options.taskStatus = status[0] === -1 ? undefined : status[0]
    options.taskTypes = types
    delete options.status
    delete options.types
    delete options.keywords
    const res = await Service.monitorTask.getTasks(options, true)
    let list = res.data && res.data.list
    this.setState({ loading: false, list })
    return list
  }

  //切换布控任务来源
  onOriginSearch = source => {
    this.setState(
      {
        source,
        loading: true
      },
      () => {
        this.handleInputSearch(this.state.keywords)
      }
    )
  }
  // 切换布控任务状态
  onStatusChange = taskStatus => {
    this.setState(
      {
        status: [taskStatus]
      },
      () => {
        this.getTaskList().then(result => {
          this.getAlarmUnResolveNum(result)
          this.getAlarmHistoryId(result, true)
        })
      }
    )
  }
  /*设置忽略/取消忽略他人授权的布控任务报警 */
  setWhetherIgnoreAlarm = (e, data) => {
    Utils.stopPropagation(e)
    let confirmOptions = {
      taskId: data.id,
      ignore: data.isPush === 0 ? 1 : 0
    }
    this.confirmOptions = confirmOptions
    this.ignoreStatus = data.ignoreStatus === 0 ? 1 : 0
    this.setState({
      confirmVisible: true
    })
  }
  // 设置忽略/取消忽略他人授权的布控任务报警 --- 二次确认取消
  handleCancelIgnoreStatus = () => {
    this.setState({
      confirmVisible: false
    })
    this.confirmOptions = {}
  }
  handleOkIgnoreStatus = () => {
    const { taskId, ignore } = this.confirmOptions
    // prettier-ignore
    Service.monitorTask.updateTaskPush({id: taskId,isPush: ignore}).then(res => {
        if (res.code === 0) {
          message.success('设置成功');
          this.setState({
            confirmVisible: false,
          });
          this.getTaskList().then(result => {
            if (!result) {
              return
            }
            this.getAlarmUnResolveNum(result)
            this.getAlarmHistoryId(result, true)
          })
        }
      })
      .catch(err => {
        message.error(err.data.message);
      });
  }

  /**布控名称搜索 */
  handleInputSearch = (keywords = '') => {
    this.setState({ keywords })
    this.getTaskList({ keywords }).then(result => {
      this.getAlarmUnResolveNum(result)
      this.getAlarmHistoryId(result, true)
    })
  }

  // 全选
  alarmCheckAll = e => {
    const { onTaskChange } = this.props
    let checkValue = e.target.checked
    this.setState({
      alarmIds: checkValue ? this.alarmAllIds : [],
      checkAll: checkValue
    })
    onTaskChange && onTaskChange(checkValue ? this.alarmAllIds : [])
  }
  // 布控任务选中
  changeAlarmTask = id => {
    const { onTaskChange } = this.props
    const alarmIds = [id]
    const checkAll = this.alarmAllIds.length === 1
    this.setState({
      alarmIds,
      checkAll
    })
    onTaskChange && onTaskChange(alarmIds)
  }
  // 告警数量格式化
  numberFormat(num) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }
  //判断布控任务状态
  taskTypeStr = item => {
    const statusData = statusMap.find(v => v.value === item.status || v.value === item.taskStatus)
    return statusData
  }

  // 渲染布控任务列表项
  renderItem = v => {
    const { alarmIds, checkAll } = this.state
    const { statusCls, statusLabel } = this.taskTypeStr(v)
    return (
      <List.Item key={v.id}>
        <div
          className={`item 
            ${alarmIds.find(item => item == v.id) && 'active'} 
            ${checkAll ? 'check-all' : ''}
          `}
          onClick={() => this.changeAlarmTask(v.id)}
        >
          <p className="title-name" title={v.name || v.taskName}>
            {v.name || v.taskName}
          </p>
          <div className="item-content">
            <div className="item-status">
              <span className={`status ${statusCls}`} />
              <span>{statusLabel}</span>
            </div>
            <div className="item-num">
              <span className="num">{(!!v.unhandledAlarmCount && this.numberFormat(+v.unhandledAlarmCount)) || 0}</span>
              {(v.taskStatus === 1 || v.status === 1) && (
                <div className="num-box">
                  <span
                    className="icon-span"
                    title={`${v.ignoreStatus === 0 || v.isPush === 0 ? '关闭' : '开启'}实时提醒`}
                    onClick={e => this.setWhetherIgnoreAlarm(e, v)}
                  >
                    <IconFont type={v.ignoreStatus === 0 || v.isPush === 0 ? 'icon-S_Photo_AlarmOpen' : 'icon-S_Photo_AlarmClose'} />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </List.Item>
    )
  }

  render() {
    const { loading, list = [], confirmVisible, source, status, checkAll } = this.state
    const listType = this.props.subscription ? '订阅' : '任务'
    //取到所有的布控告警id
    this.alarmAllIds = list.map(v => v.id)

    return (
      <div className="alarm-list-wrapper">
        <div className="search-group">
          <SearchInput size={'default'} placeholder={`请输入${listType}名称搜索`} onChange={this.handleInputSearch} style={{ width: '100%' }} />
        </div>
        <TaskListFilter
          disabled={loading}
          originType={source}
          taskStatus={status[0]}
          onOriginSearch={this.onOriginSearch}
          onStatusChange={this.onStatusChange}
        />
        <div className="list-total">
          <span className="list-total-text">我的{listType}列表：</span>
          {!!list.length && (
            <div className="list-total-checkbox">
              <span className="checkbox-span">全部显示</span>
              <Popover
                overlayClassName={'checkbox-span-pop'}
                placement="bottom"
                content={<div className="checkbox-span-pop-div">请选择下面列表查看单个{listType}告警</div>}
              >
                <Checkbox onChange={this.alarmCheckAll} checked={checkAll} />
              </Popover>
            </div>
          )}
        </div>
        <div className="list alarm-list">
          {loading ? (
            <Loading />
          ) : (
            <List locale={{ emptyText: <NoData title="告警" imgType={1} /> }} dataSource={list} renderItem={this.renderItem} />
          )}
        </div>
        <ConfirmComponent
          title={`${this.confirmOptions.ignore === 0 ? '开启' : '关闭'}实时提醒确认`}
          visible={confirmVisible}
          onCancel={this.handleCancelIgnoreStatus}
          onOk={this.handleOkIgnoreStatus}
          className="ignore-status-model"
          width={320}
          icon={this.confirmOptions.ignore === 1 ? 'icon-S_Photo_AlarmClose' : 'icon-S_Photo_AlarmOpen'}
          children={
            <div className="box-model">
              点击“确认”
              {`${this.confirmOptions.ignore === 0 ? '开启' : '关闭'}`}该{listType}的实时提醒
            </div>
          }
        />
      </div>
    )
  }
}

export default TaskList
