import React from 'react';
import { Select } from 'antd';
import './index.less';

const Option = Select.Option;
const IconFont = Loader.loadBaseComponent('IconFont');

export default ({ disabled, originType, onOriginSearch, taskStatus, onStatusChange }) => {
  return (
    <div className="task-button-group">
      <span>
        <span className="text-second-tip">来源 : </span>
        <Select size="small" value={originType} onChange={onOriginSearch} disabled={disabled} suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}>
          <Option value={0}>全部</Option>
          <Option value={1}>本地任务</Option>
          <Option value={2}>指派任务</Option>
        </Select>
      </span>
      <span>
        <span className="text-second-tip">状态 : </span>
        <Select size="small" value={taskStatus} onChange={onStatusChange} disabled={disabled} suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}>
          <Option value={-1}>全部</Option>
          <Option value={1}>运行中</Option>
          <Option value={2}>未开始</Option>
          <Option value={0}>已暂停</Option>
          <Option value={3}>已过期</Option>
          <Option value={4}>已删除</Option>
        </Select>
      </span>
    </div>
  );
};
